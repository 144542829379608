export default {
  data () {
    return {
      permissions: null,
      role: null,
      CONSTANTS: {
        // Module Constants
        equipment: 'equipment',
        equipments: 'equipment',
        custommodule: 'custommodule',
        projectdeviation: 'projectdeviation',
        projectequipment: 'projectequipment',
        drawing: 'projectdrawing',
        ndt: 'projectndt',
        'pressure-test-report': 'projectpressuretest',
        'weld-log': 'projectweldlog',
        'surface-report': 'projectsurfacereport',
        'technical-report': 'projecttechnicalreport',
        'material-list': 'projectmateriallist',
        pwht: 'projectpwht',
        pwps: 'projectpwps',
        wps: 'projectwps',
        warehouse: 'warehouse',
        deviations: 'deviation',
        checklists: 'checklists',
        projects: 'projects',
        chemicallist: 'chemicallist',
        // CRUD Actions
        LIST: 'list',
        CREATE: 'create',
        EDIT: 'edit',
        DELETE: 'delete'

      }
    }
  },
  created () {
    this.permissions = this.$formatter.cloneVariable(this.$store.state.auth.permissions)
    this.role = this.$formatter.cloneVariable(this.$store.state.auth.role)
  },
  methods: {
    isAdmin () {
      return ['admin', 'superadmin'].includes(this.role.slug)
    },
    modulePermissions (module) {
      return this.permissions[module]
    },
    hasPermissionObj (module) {
      return Boolean(this.permissions && this.permissions[module])
    },
    checkActionPermission (module, action) { // moduleName and action
      if ([this.CONSTANTS.pwps, this.CONSTANTS.wps].includes(module) && !['list', 'edit'].includes(action)) return false
      let hasPermission = false
      if ((this.permissions && this.permissions[module])) {
        switch (action) {
          case 'list':
            hasPermission = (this.isAdmin() || this.permissions[module].list)
            break
          case 'create':
            hasPermission = (this.isAdmin() || (this.permissions[module].list && this.permissions[module].create))
            break
          case 'edit':
            hasPermission = (this.isAdmin() || (this.permissions[module].list && this.permissions[module].edit))
            break
          case 'delete':
            hasPermission = (this.isAdmin() || (this.permissions[module].list && this.permissions[module].delete))
            break
          default:
            break
        }
      } else hasPermission = true
      return hasPermission
    }
  }
}
